import { createApp } from 'vue'
import App from './App.vue'

import router from './router'
import Header from './components/Header.vue'
import { Button, Select, Modal, Carousel, Spin, Statistic } from 'ant-design-vue'
import i18n from './i18n'

const app = createApp(App)
app.use(router)
app.use(i18n)
app.component('Header', Header)
app.use(Button).use(Select).use(Modal).use(Carousel).use(Spin).use(Statistic)
app.mount('#app')
