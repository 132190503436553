import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: '/',
    component: () => import('../views/Home/Index.vue')
  },
  {
    path: '/notice',
    component: () => import('../views/Home/Notice.vue')
  },
  {
    path: '/notice/detail',
    component: () => import('../views/Home/Detail.vue')
  },
  {
    path: '/address',
    component: () => import('../views/Address.vue')
  },
  {
    path: '/login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/register',
    component: () => import('../views/Register.vue')
  },
  {
    path: '/mining',
    component: () => import('../views/Mining/Index.vue')
  },
  {
    path: '/mining/mine',
    component: () => import('../views/Mining/Order.vue')
  },
  {
    path: '/mining/detail',
    component: () => import('../views/Mining/Detail.vue')
  },
  {
    path: '/mining/record',
    component: () => import('../views/Mining/Record.vue')
  },
  {
    path: '/wallet',
    component: () => import('../views/Wallet/Index.vue')
  },
  {
    path: '/wallet/record',
    component: () => import('../views/Wallet/Record.vue')
  },
  {
    path: '/wallet/recharge',
    component: () => import('../views/Wallet/Recharge.vue')
  },
  {
    path: '/mine',
    component: () => import('../views/Mine/Index.vue')
  },
  {
    path: '/level',
    component: () => import('../views/Level.vue')
  },
  {
    path: '/message',
    component: () => import('../views/Message.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

const whiteList = ['/login', '/register']
router.beforeEach((to, from) => {
  if(whiteList.indexOf(to.path) === -1) {
    const token = localStorage.getItem('token')
    if(!token) {
      return '/login'
    }
  }
})

export default router;
