import { get, post } from './http'

// 发送验证码
export const getCode = p => post('/index/Register/getCode', p);

// 用户注册
export const register = p => post('/index/Register/register', p);

// 登录
export const login = p => post('/index/Register/login', p);

// 退出登录
export const logout = p => post('/index/register/logout', p);

// 登录密码重置
export const passwordReset = p => post('/index/Register/passwordReset', p);

// 获取用户信息
export const userInfo = p => get('/index/user/userInfo', p);

// 登录状态发送邮箱验证码
export const getEmailCode = p => post('/index/User/getCode', p);

// 用户安全密码重置
export const pwdReset = p => post('/index/user/pwdReset', p);

// 获取用户地址
export const getUserAddress = p => get('/index/user/getUserAddress', p);

// 地址增加编辑
export const addUserAddress = p => post('/index/user/addUserAddress', p);

// 我的推荐
export const teamInfo = p => get('/index/user/teamInfo', p);

// 用户资产
export const getWalletInfo = p => get('/index/user/getWalletInfo', p);

// 资金记录
export const transferLog = p => get('/index/user/transferLog', p);

// 提现申请
export const withdrawApply = p => post('/index/user/withdrawApply', p);

// 消息列表
export const msgList = p => get('/index/user/msgList', p)

// 消息已读
export const readMsg = p => post('/index/user/readMsg', p)
